const templateVariantSettings = {
  header: "DESKTOP",
  homePage: "HomeTemplateV6",
  websiteName: "Patriot CU",
  about: null,
  contact: "ContactUsTemplateV6",
  footer: null,
};

module.exports = templateVariantSettings;
