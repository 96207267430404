import React from "react";
import { Router } from "@reach/router";
import {
  HomePage,
  ContactUsPage,
  DynamicPage,
  AboutUsPage,
  homeTemplatesVersionVariantTypes,
  aboutUsTemplatesVersionVariantsTypes,
  contactUsTemplatesVersionVariantsTypes,
  dynamicTemplatesVersionVariantsTypes,
  colorsTypes,
  colorsVariantsTypes,
} from "@hellobuild/blossom-websites-core-package";

import templateVariantSettings from "config";

// import { HOMEPAGE_DATA_MODEL } from "data/cms-models";

function IndexPage() {
  //  Propiedades comunes entre los Pages/Paginas
  const commonProps = {
    // Configuracion inicial de la plantilla
    config: templateVariantSettings,
    // Habilitar o Desabilitar background de tipo sombra al abrir el menu de navegacion
    // backgroundScreenHeader: false,
    //  Configuracion personalizada del Navigation Bar (Menu de Navegacion)
    navigationMenuOptions: {
      // Cambia el color de fondo de los item del menu cuando esta activo
      subMenuActiveBackgroundColor: colorsTypes.PRIMARY_VERY_ULTRA_LIGHT,
      // Cambiar la altura del sub-menu de navegacion
      dropdownSeparationFromBottom: 0,
      dropdownHoverBackground: colorsVariantsTypes.TRANSPARENT,
    },
    // Personalizar Footer
    footerOptions: {},
  };

  return (
    <Router>
      <HomePage
        path="/"
        variant={homeTemplatesVersionVariantTypes.HOME_TEMPLATE_V6}
        {...commonProps}
        // Forzar el uso de un Template
        // forceCustomVariant
        // variant={homeTemplatesVersionVariantTypes.HOME_TEMPLATE_V5}
        // Personalizar boton de login en el menu de navegacion
        headerLoginButtonStyles={{
          //  Habilitar o des-habilitar escala de grises
          // grayscale: true,
          //  Cambiar el color de fondo en su estado (hover) y con la escala de grises habilitada
          // hoverGrayscaleFillBackgroundColor: "rgba(196, 196, 196, 0.5)",
          //  Cambiar el color de bordes en su estado (hover) y con la escala de grises habilitada
          // hoverGrayscaleFillBorderColor: colorsTypes.WHITE,
          //  Cambiar el color de textos en su estado (hover) y con la escala de grises habilitada
          hoverGrayscaleFilledTextColor: colorsTypes.WHITE,
        }}
        // Personalizar Hero
        heroProps={{
          // Personalizar estilos de los botones
          buttonStylesProps: {
            //  Habilitar o des-habilitar escala de grises
            // grayscale: true,
            // Cambiar color de fondo en su estado (hover) y cuando esta habilitada la escala de grises
            // hoverGrayscaleFillBackgroundColor: "rgba(196, 196, 196, 0.5)",
            // Cambiar color de bordes en su estado (hover) y cuando esta habilitada la escala de grises
            // hoverGrayscaleBorderColor: colorsTypes.WHITE,
            // Cambiar color de textos en su estado (hover) y cuando esta habilitada la escala de grises
            // hoverGrayscaleFilledTextColor: colorsTypes.WHITE,
          },
        }}
        // Personalizar Lista de Productos e items
        productsProps={{}}
        // Personalizar Feature
        featureProps={{}}
        // Personalizar Feature Full
        featureFullProps={{}}
        // Personalizar Callout
        calloutProps={{}}
        // Personalizar Callout Mobile
        calloutMobileProps={{}}
        // Personalizar Callout Compressed
        calloutCompressedProps={{}}
      />

      <AboutUsPage
        path="/about-us"
        variant={aboutUsTemplatesVersionVariantsTypes.ABOUT_US_TEMPLATE_V6}
        {...commonProps}
        // Personalizar Page Header
        pageHeaderProps={{}}
        // Personalizar Body Text
        bodyTextProps={{}}
        // Personalizar Callout Text
        calloutTextProps={{}}
        // Personalizar Callout Button
        calloutButtonProps={{}}
      />

      <ContactUsPage
        path="/contact-us"
        variant={contactUsTemplatesVersionVariantsTypes.CONTACT_US_TEMPLATE_V6}
        {...commonProps}
        // Personalizar Submit Button
        submitButton={{}}
        // Personalizar Header
        pageHeaderProps={{}}
        // Personalizar Page Title
        pageTitleProps={{}}
        // Personalizar Contact
        contactProps={{}}
        // Personalizar Branch
        branchProps={{}}
        // Personalizar  Callout
        calloutProps={{}}
        // Personalizar  Callout Mobile With Gradient
        calloutMobileWithGradientPros={{}}
      />

      <DynamicPage
        path="/:id"
        variant={dynamicTemplatesVersionVariantsTypes.DYNAMIC_TEMPLATE_V6}
        {...commonProps}
        // Personalizar Custom Page
        customPageProps={{}}
        // Personalizar Hero
        heroProps={{}}
        // Personalizar Lista de Productos
        productsProps={{}}
        // Personalizar Feature Full
        featureFullProps={{}}
        // Personalizar Feature
        featureProps={{}}
        // Personalizar Callout
        calloutProps={{}}
        // Personalizar Callout Mobile
        calloutMobileProps={{}}
        // Personalizar Callout Compressed
        calloutCompressedProps={{}}
      />
    </Router>
  );
}

export default IndexPage;
